window.Hyerhub = window.Hyerhub || {};

class HubbadoBanner {
  constructor() {
    this.container = document.querySelector('.top-banners');
    this.template = this.container.querySelector('.template').innerHTML;
    const urlParams = new URLSearchParams(document.location.search);
    if(!document.referrer.includes('hubbado.com')) {
      return
    }
    if(!urlParams.has("utm_source", "hubbado")){
      return
    }
    if(!urlParams.has("utm_medium", "banner")){
      return
    }
    if(!urlParams.has("utm_campaign", "hyerhub-launch")){
      return
    }
    this._bind();
  }

  message(msg) {
    const message = document.createElement('div');
    message.classList.add('flash', 'hubbado-banner', 'warning');
    message.innerHTML = this.template;
    const messageContainer = message.querySelector('.message');
    messageContainer.innerHTML = msg;
    this.container.append(message);
  }

  _bind() {
    this.message("<content><strong>You've been redirected to Hubbado's new recruitment arm Hyerhub! </strong> <p>All your usual Hubbado recruitment services, consultants and resourcing team are here.</p></content><a class='btn secondary' href='/how-it-works'>How it works</a>");
    this.container.addEventListener('click', (e) => {
      const closeButton = e.target.closest('.close-button');
      if (!closeButton) return;
      const message = closeButton.closest('.flash');
      message.remove();
      window.dispatchEvent(new Event('resize'));
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  Hyerhub.HubbadoBanner = new HubbadoBanner();
});
